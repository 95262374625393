import ChatHeader from "./chatheader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faClose, faFileAudio, faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState} from "react";
import axios from "axios";
import ChatLoginForm from "./chatloginform";
import './cqi.scss';
import {
  postIdleTimeout, sessionTimeout,
  TEST, URL, CQI,
  timestampToTime, errorHandler, setCookie, eraseCookie, getREST_config, getFakeData,
} from "./tools";
import {FileUploader} from "react-drag-drop-files";
const CONFIG = require("./config.json");

const CallQualityInspectorPlugin = (props) => {

  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('accessToken') !== null && localStorage.getItem('accessToken') !== '');
  const [accessToken, setAccessToken] = useState(localStorage.getItem('accessToken') === null
                                                          ? '' : localStorage.getItem('accessToken'));
  const [userId, setUserId] = useState("");
  useEffect( () => {
    console.log(CQI.logPrefix + "useEffect - accessToken: ", accessToken);
    if(accessToken !== '') {
      localStorage.setItem('accessToken', accessToken);
      setCookie('api_key', accessToken, 1);
    }
    else {
      localStorage.clear();
      eraseCookie('api_key');
    }
    setIsLoggedIn(!!accessToken);
  }, [accessToken]);

  useEffect( () => {
    console.log(CQI.logPrefix + "useEffect - isLoggedIn: ", isLoggedIn);
    if(isLoggedIn) {
      props.setIsLoggedIn(isLoggedIn);
      localStorage.setItem('lastIdle', Date.now().toString());
    }
  }, [isLoggedIn]);

  useEffect( () => {
    console.log(CQI.logPrefix + "useEffect - props.isLoggedIn: ", props.isLoggedIn);
    setIsLoggedIn(props.isLoggedIn);
  }, [props.isLoggedIn]);

  const Logout = () => {
    console.log(CQI.logPrefix + "Logout");
    // Reset();
    setAccessToken('');
    setIsLoggedIn(false);
    props.setIsLoggedIn(false);
  }

  const [showCQPopup, setShowCQPopup] = useState(false);
  useEffect( () => {
    console.log(CQI.logPrefix + "useEffect - props.opened: ", props.opened);
    if(props.opened !== 'cqi') {
      setShowCQPopup(false);
    }
  }, [props.opened]);

  const [processing, setProcessing] = useState(false);
  const [file, setFile] = useState(null);
  useEffect( () => {
    console.log(CQI.logPrefix + "useEffect - file: ", file);
    if(file !== null) {
      setDropArea(<div className={"inside"}><p>Selected file: {file.name}</p></div>);
    }
    else {
      setDropArea(CQI.dropAreaInitText)
    }
  }, [file]);
  const [networkError,setNetworkError] = useState(false);
  const uploadFile = async (e) => {
    e.preventDefault();
    setNetworkError(false);
    if (!file) {
      alert('Please select a file.');
      return;
    }

    try {
      const formData = new FormData();
      formData.append('files', file);
      formData.append('language',  "hu");
      setProcessing(true);
      let response;
      if(!file) {
        response = await getFakeData();
      }
      else {
        response = await axios.post(
          CQI.URL + (CQI.TEST ? CQI.testURL : '/api/v1/callquality/upload_file'),
          formData,
          getREST_config(CONFIG.AxiosAuth, accessToken, true));
      }
      console.log(CQI.logPrefix + 'File uploaded successfully!', response.data.output);
      setData(response.data.output);
      setProcessing(false);
    } catch (error) {
      setData(null);
      console.error(CQI.logPrefix + 'Error uploading file:', error);
      setNetworkError(true);
      setProcessing(false);
    }
  };

  const [data,setData] = useState(null);
  const [phrasesSentiment, setPhrasesSentiment] = useState([]);
  useEffect(() => {
    if(data) {
      console.log(CQI.logPrefix + "useEffect - data: ", data);
      const sum = [0,1,2,3].map( (i) => {   //  max 4 speakers
        return ['negative','neutral','positive'].map( (v) => {  //  sentiment levels
          return data.phrases.map( (d) => {
            if(d.speaker_number === i && typeof d.sentiment[v] !== 'undefined')
              return d.sentiment[v];
            else
              return false;
          }).filter(item => item !== false)   //  clear empty values
        })
      });
      setPhrasesSentiment(sum);
    }
  }, [data]);
  useEffect( () => {
    if(phrasesSentiment.length) {
      console.log(CQI.logPrefix + "useEffect - phrasesSentiment: ", phrasesSentiment);
    }
  }, [phrasesSentiment]);

  const [visibleTab, setVisibleTab] = useState('phrases');
  const [dropArea, setDropArea] = useState(CQI.dropAreaInitText);

  const Reset = () => {
    setFile(null);
    setData(null);
  }

  return <>
    <div className={"cqi-plugin position-from-right__" + (props.positionFromRight ? props.positionFromRight: '0')}>
      <div className={"cqi-popup " + (showCQPopup ? "show" : "hide") + (isLoggedIn ? "" : " login")}>
        <div className={"cqi-header-wrapper"}>
          <ChatHeader
            chatTitle={"Call Quality Inspector"}
            showLogoutButton={isLoggedIn}
            Logout={Logout}
            showChatOptionsButton={false}
            showChatOptions={false}
            setShowChatOptions={() => {}}
            showChatPopup={setShowCQPopup}
            setShowChatPopup={setShowCQPopup}
            processing={processing}
          />
        </div>
        {!isLoggedIn && <>
          <ChatLoginForm token={setAccessToken}
                         url={URL}
                         logPrefix={CQI.logPrefix}
                         setUserId={setUserId}
                         setProcessing={setProcessing}
                         processing={processing}/>
        </>}
        {isLoggedIn && <>
          <div className={"body"} >
            {!data && <>
              <div className={"init"}>
                <div className={"file-uploader"}>
                  <form onSubmit={uploadFile}>
                    <FileUploader
                        handleChange={(e) => { setFile(e); }}
                        name="files"
                        multiple={false}
                        children={dropArea}
                        types={CQI.fileTypes} />
                    <button type="submit"
                            className={"upload"}
                            disabled={!file}
                    >Upload</button>
                    {networkError && <div className={"error-message"}>Network error, try again</div>}
                  </form>
                </div>
              </div>
            </>}
            {data && <>
              <p className={"uploaded-file-name"}>{data && data.source}
                <FontAwesomeIcon icon={faTrashAlt}
                                 className={"reset"}
                                 onClick={Reset}
                />
              </p>
              <div className={'tabs'}>
                <ul className={'controllers'}>
                  <li onClick={() => {setVisibleTab('phrases')}}
                      className={(visibleTab === 'phrases' ? 'show' : '')}>Phrases</li>
                  <li onClick={() => {setVisibleTab('quality')}}
                      className={(visibleTab === 'quality' ? 'show' : '')}>Quality</li>
                </ul>
                <div className={'tab phrases ' + (visibleTab === 'phrases' ? 'show' : '')}>
                  {phrasesSentiment.length && phrasesSentiment.map((speaker, speaker_i) => {
                    if (speaker[0].length)
                      return <span key={speaker_i}>
                        <strong>Speaker {speaker_i + 1} </strong>
                        {speaker.map((sentiment, sentiment_i) => {
                          return <span key={sentiment_i}>
                            {['negative', 'neutral', 'positive'][sentiment_i]}:&nbsp;
                            {parseFloat(
                              (parseFloat(sentiment.reduce((acc, cV) => {
                                return acc + cV
                              }, 0)) / sentiment.length) * 100
                          ).toFixed(1)} % &nbsp;&nbsp;&nbsp;</span>;
                      })}<br/></span>;
                    else
                      return false;
                  })}
                  <table>
                    <tbody>
                    {data && data.phrases.map((d, i) => {
                      return <tr className={`row_${d.id} speaker_${d.speaker_number} `} key={`k${i}`}>
                        <td title={JSON.stringify(d)}>{d.id + 1}</td>
                        <td>{d.text}</td>
                        <td>
                          <div className={'sentiment'}>
                            {(typeof d.sentiment.negative !== 'undefined') && (typeof d.sentiment.neutral !== 'undefined') && (typeof d.sentiment.positive !== 'undefined') && <>
                              <div className={'negative'}
                                   title={`negative (${Math.round(d.sentiment.negative * 100)}%)`}
                                   style={{width: (d.sentiment.negative * 100)}}></div>
                              <div className={'neutral'} title={`neutral (${Math.round(d.sentiment.neutral * 100)}%)`}
                                   style={{width: (d.sentiment.neutral * 100)}}></div>
                              <div className={'positive'}
                                   title={`positive (${Math.round(d.sentiment.positive * 100)}%)`}
                                   style={{width: (d.sentiment.positive * 100)}}></div>
                            </>}
                          </div>
                        </td>
                        <td title={'Confidence'}>
                          <div className={'confidence'}>{Math.round(d.confidence * 100)}%</div>
                        </td>
                      </tr>;
                    })}
                    </tbody>
                  </table>

                </div>
                <div className={'tab quality ' + (visibleTab === 'quality' ? 'show' : '')}>
                  <table>
                    <tbody>
                    {data && data.quality.map((d, i) => {

                      return <tr key={`k${i}`}>
                                {/*<td className={'index'} title={JSON.stringify(d)}>{i + 1}</td>*/}
                                <td className={'question'}>{d.Question}</td>
                                <td className={'answer'}>{d.Answer}</td>
                                <td className={'justification'}>{d.Justification}</td>
                              </tr>;
                    })}
                    </tbody>
                  </table>
                </div>
              </div>
            </>}
          </div>
          <div className={"footer"} />
        </>}
      </div>
      <div className={"cqi-button icon-button " + (showCQPopup ? "close" : "open")}
           onClick={() => {
             setShowCQPopup(!showCQPopup);
             props.setOpened('cqi');
             setIsLoggedIn(props.isLoggedIn);
           }}>
        <FontAwesomeIcon
          icon={(showCQPopup ? faClose : faFileAudio)}
          className={"icon"}
        />
      </div>
    </div>
  </>;
}
export default CallQualityInspectorPlugin;
