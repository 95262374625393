import React, {useState} from 'react';
import CallQualityInspectorPlugin from "./cqiplugin";
import ChatPlugin from "./chatplugin";
function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [opened, setOpened] = useState(false); // 'cqi', 'chat', 'mail'
  return <>
    <CallQualityInspectorPlugin
          positionFromRight={1}
          isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn}
          opened={opened} setOpened={setOpened}
    />
    <ChatPlugin
          positionFromRight={0}
          isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn}
          opened={opened} setOpened={setOpened}
    />
  </>
}
export default App
