import ChatHeader from "./chatheader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faClose,
  faMessage,
  faMicrophone,
  faMicrophoneSlash,
  faPaperPlane, faPause,
  faTrash,
  faVolumeHigh
} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import ChatLoginForm from "./chatloginform";
import './chat.scss';
import {
  langs,
  logPrefix,
  postIdleTimeout,
  sessionTimeout,
  TEST,
  URL,
  timestampToTime,
  showErrorMessageTimeout, errorHandler, setCookie, eraseCookie, getREST_config
} from "./tools";
import sdk from "microsoft-cognitiveservices-speech-sdk";
const CONFIG = require("./config.json");

const ChatPlugin = (props) => {

  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('accessToken') !== null && localStorage.getItem('accessToken') !== '');
  const [accessToken, setAccessToken] = useState(localStorage.getItem('accessToken') === null
                                                          ? '' : localStorage.getItem('accessToken'));
  const [userId, setUserId] = useState('');
  useEffect( () => {
    console.log("useEffect - accessToken: ", accessToken);
    if(accessToken !== '') {
      localStorage.setItem('accessToken', accessToken);
      setCookie('api_key', accessToken, 1);
    }
    else {
      localStorage.clear();
      eraseCookie('api_key');
    }
    setIsLoggedIn(!!accessToken);
  }, [accessToken]);

  useEffect( () => {
    console.log(logPrefix + "useEffect - isLoggedIn: ", isLoggedIn);
    if(isLoggedIn) {
      if(Object.keys(domains).length === 0)
         getDomains();

      props.setIsLoggedIn(isLoggedIn);
      localStorage.setItem('lastIdle', Date.now().toString());
    }
  }, [isLoggedIn]);

  useEffect( () => {
    console.log(logPrefix + "useEffect - props.isLoggedIn: ", props.isLoggedIn);
    setIsLoggedIn(props.isLoggedIn);
  }, [props.isLoggedIn]);

  const postIdle = () => {
    return;
    if(!isLoggedIn) return;
    if(!localStorage.getItem('lastIdle'))
      localStorage.setItem('lastIdle', Date.now().toString());
    const elapsedTime = (Date.now() - parseInt(localStorage.getItem('lastIdle'), 10)) ;
    console.log(logPrefix + "last sent idle request: ", timestampToTime(elapsedTime) );

    if(elapsedTime > sessionTimeout) {
      Logout();
      return;
    }

    if(elapsedTime > postIdleTimeout ) {
      return axios
        .post(URL + "/idle",
          {},
          getREST_config(CONFIG.AxiosAuth, accessToken),
        )
        .then((response) => {
          if (response.status === 200) {
            localStorage.setItem('lastIdle', Date.now().toString());
            console.log(logPrefix + 'Idle request sent');
            return response.data;
          } else {
            Logout();
            return null;
          }
        })
        .catch((error) => {
          errorHandler(error, Logout);
        });
    }
  }
  const Logout = () => {
    console.log(logPrefix + "Logout");
    setAccessToken('');
    setDomains({});
    localStorage.clear();
    setIsLoggedIn(false);
    props.setIsLoggedIn(false);
  }

  useEffect( () => {
     // console.log(logPrefix + "useEffect - props.opened: ", props.opened);
     if(props.opened !== 'chat') {
       setShowChatPopup(false);
     }
  }, [props.opened]);
  const [showChatPopup, setShowChatPopup] = useState(false);
  const [showChatOptions, setShowChatOptions] = useState(true); //  when there isn't selected knowledge base
  const chatTitle = "";
  const [chatId, setChatId] = useState("tmp");
  const [chatBody, setChatBody] = useState([]);
  const [sendWithEnter, setSendWithEnter] = useState(true);
  const questionInput = useRef(0);
  const chatBodyRef = useRef();
  useEffect(() => {
    if(chatBody.length)
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
  }, [chatBody]);
  const addBody = (question, qTime, message) => {
    const [hour, minutes,seconds] = new Date().toLocaleTimeString().slice(0, 8).split(':');
    setChatBody( [...chatBody,<div key={`${hour}${minutes}${seconds}`}>
      <div className={'question'}>
        <div className={'wrapper'}>
          <div className={'message'}>{question}</div>
        </div>
        <div className={'wrapper'}>
          <div className={'time'}>{qTime}</div>
        </div>
      </div>
      <div className={'answer'}>
        <div className={'wrapper'}>
          <div className={'message'} id={"response_" + message.response_id}>
            <span>{message.answer.split('\n').map((part, index) => (
              <div key={index}>{part}</div>
            ))
            }</span>
            <FontAwesomeIcon
              icon={faVolumeHigh}
              className={"text2speech " + (reading ? "reading" : "")}
              onClick={() => {
                setAnswer(message.answer);
                setReading(true);
              }}
            />
            <FontAwesomeIcon
              icon={faPause}
              className={"text2speechStop"}
              onClick={PauseReading}
            />
          </div>
        </div>
        <div className={'wrapper'}>
          <div className={'time'}>{hour}:{minutes}:{seconds}</div>
        </div>
      </div></div>]
    )
  }
  const sendChatQuestion = () => {
    console.log(logPrefix + 'Click to chat putQuestion button');
    if(domain === -1) {
      console.log(logPrefix + "Domain is not selected");
      setDomainError(true);
      setDomainErrorMessage(true);
      setShowChatOptions(true);
      setTimeout(() => {
        setDomainErrorMessage(false);
      }, showErrorMessageTimeout);

      return false;
    }
    if(question === '') {
      console.log(logPrefix + "Empty question");
      return false;
    }

    setQuestion(questionInput.current.value);
    if(question === null) // only the initial state is null
      return;

    const [hour, minutes, seconds] = new Date().toLocaleTimeString().slice(0, 8).split(':');
    setProcessing(true);
//    addBody(question,`${hour}:${minutes}:${seconds}`,question);
//    setProcessing(false);
    getAnswer(question, domain).then(
      (res) => {
        addBody(question,`${hour}:${minutes}:${seconds}`,res);
        setQuestion('');
        setShowChatOptions(false);
        setProcessing(false);
      }
    );

  }

  const [domains,setDomains] = useState(localStorage.getItem('domains') === null
                                        ? {} : JSON.parse(localStorage.getItem('domains')));
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState("");
  const [processing, setProcessing] = useState(false);
  const [domain, setDomain] = useState(-1);
  const [person, setPerson] = useState("hu-HU-TamasNeural");
  const [detectedLang, setDetectedLang] = useState(navigator.language || navigator.userLanguage);
  const [listening, setListening] = useState(false);
  const [reading, setReading] = useState(false);
  const [blink, setBlink] = useState('');
  const [domainErrorMessage, setDomainErrorMessage] = useState(false);
  const [domainError, setDomainError] = useState(null);

  const getDomains = () => {
    if(accessToken === '')
      return;
    return axios
      .get(
        URL + '/api/v1/dms/collections',
        getREST_config(CONFIG.AxiosAuth, accessToken),
      )
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem('domains',JSON.stringify(response.data));
          setDomains(response.data);
          return response.data;
        } else {
          Logout();
          return null;
        }
      })
      .catch((error) => {
        errorHandler(error, Logout);
      });
  }

  useEffect( () => {
    console.log(logPrefix + "useEffect - domainError: ", domainError);
    if(domainError) {
      setDomainErrorMessage(true);
    }
  }, [domainError]);

  useEffect( () => {
//    console.log(logPrefix + "useEffect - domainErrorMessage: ", domainErrorMessage);
    if(domainErrorMessage)
      setTimeout(() => {setDomainErrorMessage(false)},showErrorMessageTimeout);
  }, [domainErrorMessage]);
  const getAnswer = (str, collection) => {
    if(TEST) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(`sample answer text to (knowledge base: ${domain} ): ${str}`);
        }, 2000);
      });
    }
    return axios
      .post(
        URL + '/api/v1/message/chat',
        {
          "user_id": (userId === "" ? "tmp" : userId),
          "chat_id": chatId,
          "collection": collection,
          "question": str,
        },
        getREST_config(CONFIG.AxiosAuth, accessToken),
      )
      .then((response) => {
        if (response.status === 200) {
          console.log(logPrefix + 'Return axios');
          console.log(response.data);
          return response.data;
        } else {
          Logout();
          return null;
        }
      })
      .catch((error) => {
        errorHandler(error,Logout);
      });
  }

  let sdk = require("microsoft-cognitiveservices-speech-sdk");
  const speechConfig = sdk.SpeechConfig.fromSubscription("db2f1bc14df9424590ccfec349bc6baf", "westeurope");
  const [player, updatePlayer] = useState({p: undefined, muted: false});
  if(player.p !== undefined) {
    player.p.onAudioEnd = function (_) {
      setReading(false);
      console.log(logPrefix + "Reading finished");
    };
  }

  speechConfig.speechSynthesisVoiceName = person;
  if(person !== 'autodetect') {
    speechConfig.speechRecognitionLanguage = person.substring(0, 5);
  }
  const PauseReading = () => {
    console.log(logPrefix + "Pause reading click");
    updatePlayer(p => {
      if (!p.muted) {
        p.p.pause();
        return {p: p.p, muted: true};
      } else {
        p.p.resume();
        return {p: p.p, muted: false};
      }
    });
    setReading(false);
  }

  useEffect( () => {
    console.log(logPrefix + "useEffect - person: ", person);
    speechConfig.speechSynthesisVoiceName = person;
    if(person !== 'autodetect') {
      speechConfig.speechRecognitionLanguage = person.substring(0,5);
    }
  }, [person]);
  useEffect( () => {
    console.log(logPrefix + 'useEffect - reading: ', reading);
    let myPlayer = new sdk.SpeakerAudioDestination();
    updatePlayer(p => {p.p = myPlayer; return p;});
    let audioConfig  = sdk.AudioConfig.fromSpeakerOutput(myPlayer);
    let synthesizer = new sdk.SpeechSynthesizer(speechConfig, audioConfig);
    if(reading) {
      const complete_cb = function (result) {
        if (result.reason === sdk.ResultReason.SynthesizingAudioCompleted) {
          console.log(logPrefix + "synthesis finished.");
        } else {
          console.error("Speech synthesis canceled, " + result.errorDetails +
            "\nDid you set the speech resource key and region values?");
        }
        synthesizer.close();
        synthesizer = null;
        setReading(false);
      };
      const err_cb = function (err) {
        console.trace("err - " + err);
        synthesizer.close();
        synthesizer = null;
        setReading(false);
      };
      synthesizer.synthesisStarted  = function (s, e) {
        console.log(logPrefix + "Speech started");
      };
      synthesizer.speakTextAsync(answer,complete_cb,err_cb);
    }
  }, [reading]);
  useEffect( () => {
    console.log(logPrefix + "useEffect - detectedLang: ", detectedLang);
    for (const key in langs) {
      if(langs[key].code === detectedLang) {
        setPerson(langs[key].person);
        break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detectedLang]);
  useEffect( () => {
    // console.log(logPrefix + "useEffect - blink: ", blink);
    if(blink !== '') {
      setTimeout(() => {setBlink('')}, 1000);
    }
  }, [blink]);
  useEffect( () => {
    console.log(logPrefix + 'useEffect - listening: ', listening);
    if(!listening) {
      console.log(logPrefix + 'Listening stopped');
    }
    else {
      console.log(logPrefix + 'Listening');

      let audioConfig = sdk.AudioConfig.fromDefaultMicrophoneInput();
      let speechRecognizer;
      if(person === 'autodetect') {
        console.log(logPrefix + 'Recognition language: ', speechConfig.speechRecognitionLanguage );
        let autoDetectSourceLanguageConfig
          = sdk.AutoDetectSourceLanguageConfig.fromLanguages(["en-US", "hu-HU"]);
        speechRecognizer = sdk.SpeechRecognizer.FromConfig(speechConfig, autoDetectSourceLanguageConfig, audioConfig);
        speechRecognizer.recognizeOnceAsync((result) => {
            let languageDetectionResult = sdk.AutoDetectSourceLanguageResult.fromResult(result);
            setDetectedLang(languageDetectionResult.language);
          },
          {});
      }
      else {
        console.log(logPrefix + 'Recognition language: ', speechConfig.speechRecognitionLanguage );
        speechRecognizer = new sdk.SpeechRecognizer(speechConfig, audioConfig);
      }

      speechRecognizer.recognizeOnceAsync(result => {
        switch (result.reason) {
          case sdk.ResultReason.RecognizedSpeech:
            console.log(`RECOGNIZED: Text=${result.text}`);
            setQuestion((question.length > 0 && listening ? question + '\n' : '') + result.text)
            setBlink('blink-success');
            setListening(false);
            break;
          case sdk.ResultReason.NoMatch:
            console.log(logPrefix + "NOMATCH: Speech could not be recognized.");
            setBlink('blink-error');
            setListening(false);
            break;
          case sdk.ResultReason.Canceled:
            const cancellation = sdk.CancellationDetails.fromResult(result);
            console.log(`CANCELED: Reason=${cancellation.reason}`);

            if (cancellation.reason === sdk.CancellationReason.Error) {
              console.log(`CANCELED: ErrorCode=${cancellation.ErrorCode}`);
              console.log(`CANCELED: ErrorDetails=${cancellation.errorDetails}`);
              console.log(logPrefix + "CANCELED: Did you set the speech resource key and region values?");
            }
            setBlink('blink-error');
            setListening(false);
            break;
          default:
            console.log(`SOME ERROR: Reason=${result.reason}`);
        }
        speechRecognizer.close();
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }  }, [listening]);

  return <>
    <div className={"chat-plugin position-from-right__" + (props.positionFromRight ? props.positionFromRight: '0')}
         onClick={postIdle}>
      <div className={"chat-popup " + (showChatPopup ? "show" : "hide")}>
        <div className={"chat-header-wrapper"}>
          <ChatHeader
            chatTitle={chatTitle}
            showChatOptionsButton={isLoggedIn}
            showChatOptions={false}
            setShowChatOptions={() => {setShowChatOptions(!showChatOptions)}}
            showChatPopup={setShowChatPopup}
            setShowChatPopup={setShowChatPopup}
            processing={processing}
          />
          <div className={"chat-options " + (isLoggedIn && showChatOptions ? "show" : "hide")}>
            <label className={"label-select-domain"}>
              <div onClick={getDomains}>Knowledge base:</div>
              <select className={"select-domain"}
                      onChange={(e) => {
                        postIdle();
                        setDomain(e.target.value)
                      }}
                      defaultValue={"6"}
              >
                <option value="-1">Choose knowledge base</option>
                {Object.keys(domains).length > 0 && Object.entries(domains).map(([key, value], i) =>
                  <option value={value} key={i}>{value}</option>
                )}
              </select>
            </label>
            <label className={"label-select-person"}>
              <div>Recognition language / Reading</div>
              <select className={"select-person"}
                      onChange={(e) => {
                        setPerson(e.target.value)
                      }}
                      value={person !== '' ? person : 'hu-HU-TamasNeural'}
              >
                <option value="autodetect">Autodetect language</option>
                {Object.entries(langs).map((lang, i) =>
                  <option value={lang[1].person} key={lang[1].code + '-' + i}>
                    {lang[1].code} / {lang[1].person} ({lang[1].gender})
                  </option>
                )}
              </select>
            </label>
            <label className={"label-send-with-enter"}>
              <input type={"checkbox"}
                     className={"send-with-enter"}
                     onChange={() => {
                       setSendWithEnter(!sendWithEnter);
                     }}
                     checked={sendWithEnter}
              />
              <div>Send message when hit Enter </div>
            </label>
            <div className={'button-container'}>
              <button className={"logout"}
                      onClick={Logout}
              >Logout
              </button>
              <button className={"clear-history"}
                      onClick={() => {
                        setChatBody([])
                      }}
              >Clear history
              </button>
              <button className={"close"}
                      onClick={() => {
                        setShowChatOptions(!showChatOptions)
                      }}
              >Hide options
              </button>
            </div>
          </div>
        </div>
        {!isLoggedIn && <>
          <ChatLoginForm token={setAccessToken}
                         url={URL}
                         logPrefix={logPrefix}
                         setUserId={setUserId}
                         setProcessing={setProcessing}
                         processing={processing}/>
        </>}
        {isLoggedIn && <>
          <div className={"body " + (showChatOptions ? "opened-options" : "")} ref={chatBodyRef}>
            {(!chatBody.length ?
              <div className={"init"}>
                Ask! Please type your question or use the microphone button to recognize your speech</div>
              : chatBody)}
          </div>
          <div className={"footer"}>
              <textarea ref={questionInput}
                        className={"question " + blink}
                        rows={3}
                        value={!!question ? question : ''}
                        onChange={(e) => {
                          postIdle();
                          setQuestion((question.length > 0 && listening ? question + '\n' : '') + e.target.value)
                        }}
                        onKeyDown={(e) => {
                          if(sendWithEnter && e.keyCode === 13 && e.shiftKey === false) {
                            e.preventDefault();
                            sendChatQuestion();
                          }
                        }}
              />
            <div className={"buttons"}>
              <FontAwesomeIcon icon={faTrash}
                               className={"clear"}
                               onClick={() => {
                                 console.log(logPrefix + 'Click to Clear button');
                                 setQuestion('');
                               }}
              />
              <FontAwesomeIcon icon={listening ? faMicrophone : faMicrophoneSlash}
                               className={"listen " + (listening ? '_ing' : '')}
                               onClick={() => {
                                 console.log(logPrefix + 'Click to Listen button');
                                 setListening(!listening);
                               }}
              />
              <FontAwesomeIcon icon={faPaperPlane}
                               className={"putQuestion"}
                               disabled={question === ''}
                               onClick={() => {
                                 sendChatQuestion();
                               }}
              />
            </div>
          </div>
          <div className={"message error" + (domainErrorMessage ? "" : " invisible")}>
            <p>Choose a knowledge base before submitting a question</p>
            <FontAwesomeIcon
              icon={faClose}
              className={"close-error-message"}
              onClick={() => {
                setDomainErrorMessage(false)
              }}
            />

          </div>
        </>}
      </div>
      <div className={"chat-button icon-button " + (showChatPopup ? "close" : "open")}
           onClick={() => {
             props.setOpened('chat');
             setShowChatPopup(!showChatPopup);
             setIsLoggedIn(props.isLoggedIn);
           }}>
        <FontAwesomeIcon
          icon={(showChatPopup ? faClose : faMessage)}
          className={"icon"}
        />
      </div>
    </div>
  </>;
}
export default ChatPlugin;
