import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose, faCog, faRightFromBracket, faComments} from "@fortawesome/free-solid-svg-icons";
import React from "react";

const ChatHeader = (props) => {

  return (
    <>
      <div className={"loading-bg " + (props.processing ? "show" : "")}>
        <div className="loading-ring">
          <div/>
          <div/>
          <div/>
          <div/>
        </div>
      </div>
      <div className={"header"}>
        <FontAwesomeIcon icon={faComments} className={'icon'}/>
        <div className={"title"}>{props.chatTitle}</div>
        <div className={"icons"}>
          {props.showLogoutButton &&
            <FontAwesomeIcon icon={faRightFromBracket} className={"logout"}
                             title={"Logout"}
                             onClick={() => {
                               props.Logout()
                             }}/>}
          {props.showChatOptionsButton &&
            <FontAwesomeIcon icon={faCog} className={"options"}
                             title={"Show options"}
                             onClick={() => {
                               props.setShowChatOptions(!props.showChatOptions)
                             }}/>}
          <FontAwesomeIcon icon={faClose} className={"close"}
                           title={"Close popup"}
                           onClick={() => {
                             props.setShowChatPopup(!props.showChatPopup)
                           }}/>
        </div>
      </div>
    </>
  );
};

export default ChatHeader;
