import React, {useEffect, useState} from 'react';
import axios from "axios";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose} from "@fortawesome/free-solid-svg-icons";
import {getREST_config, showErrorMessageTimeout} from "./tools";

const ChatLoginForm = (props) => {
  const [chatUsername, setChatUsername] = useState('');
  const [chatUsernameError, setChatUsernameError] = useState(false);
  const [chatPassword, setChatPassword] = useState('');
  const [chatPasswordError, setChatPasswordError] = useState(false);
  const [chatLoginError, setChatLoginError] = useState(false);
  const [chatNetworkError, setChatNetworkError] = useState(false);

  const Login = (user, pass) => {
      props.setProcessing(true);
      props.token('');
      return axios
        .post(props.url + "/api/v1/auth/login",
          {
              "user_name": user,
              "password": pass,
          },
          getREST_config('login'),
        )
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            console.log(props.logPrefix + 'Success login');
            props.setUserId(user);
            props.token(response.data);
            props.setProcessing(false);
            return response;
          } else {
            props.setUserId("");
            props.setProcessing(false);
            return null;
          }
        })
        .catch((error) => {
          props.setUserId("");
          props.setProcessing(false);
          console.log(props.logPrefix + "LOGIN ERROR");
          console.log(error);

          if(error.response) {
            console.log(props.logPrefix + "error.response");
            console.log(error.response.status);
            console.log(error.response.headers);
            console.log(error.response.data);
            setChatLoginError(true);
          } else if (error.request) {
            setChatNetworkError(true)
            console.log(props.logPrefix + "error.request");
            console.log(error.request);
          } else {
            setChatNetworkError(true);
            console.log(props.logPrefix + "error.message");
            console.log('Error', error.message);
          }
          console.log(props.logPrefix + 'error.config');
          console.log(error.config);
          setTimeout(() => {
             setChatLoginError(false);
             setChatNetworkError(false);
          }, showErrorMessageTimeout);
        });
    }

  const handleLogin = async () => {
    setChatUsernameError(chatUsername === '' );
    setChatPasswordError(chatPassword === '' );

    if (chatUsername && chatPassword) {
      Login(chatUsername,chatPassword);
    }
  };

  useEffect( () => {
    setChatUsernameError(chatUsername === '' );
    setChatPasswordError(chatPassword === '' );
    console.log(props.logPrefix + "useEffect - chatUsername: ", chatUsername);
    console.log(props.logPrefix + "useEffect - chatPassword: ", chatPassword);
  }, [chatPassword, chatUsername, props.logPrefix]);

  return (
    <>
      <div className={'chat-login-form'}>
        <div className={'chat-login-form-user'}>
          <input
            type="text"
            className={"user " + (chatUsernameError ? "error" : "")}
            placeholder={"Username"}
            value={chatUsername}
            onChange={(e) => setChatUsername(e.target.value)}
          />
        </div>
        <div className={'chat-login-form-password'}>
          <input
            type="password"
            className={"pass " + (chatPasswordError ? "error" : "")}
            placeholder={"Password"}
            value={chatPassword}
            onChange={(e) => setChatPassword(e.target.value)}
          />
        </div>
        <div>
          <button onClick={handleLogin}>Login</button>
        </div>
      </div>

      <div className={"message error" + (chatLoginError ? "" : " invisible")}>
        <p>The username or password is incorrect</p>
        <FontAwesomeIcon
          icon={faClose}
          className={"close-error-message"}
          onClick={() => {
            setChatLoginError(false)
          }}
        />

      </div>
      <div className={"message error" + (chatNetworkError ? "" : " invisible")}>
        <p>A network error has occurred. Wait a few minutes and try again.</p>
        <FontAwesomeIcon
          icon={faClose}
          className={"close-error-message"}
          onClick={() => {
            setChatNetworkError(false)
          }}
        />
      </div>
    </>
  );
};

export default ChatLoginForm;
